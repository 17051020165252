<template src="./Theater.html"></template>

<script>

export default {
  name: "Theater",


  data () {
    return {

      theaters:[],
      theaters_loaded:false,


    }
  },

  created(){
    console.log('theater');

    this.loadPlayhouses();
  },

  methods:{
    // информация о мероприятии

    loadPlayhouses: function() {


      let pr2 = this.$store.state.api.loadPlayhouses();

      pr2.then( data =>{
        console.log(data);

        this.theaters = data;
        this.theaters_loaded = true;

      })

    },


  },
}
</script>

<style scoped>

</style>
